import React from "react";
import Footer from "@/bit/components/components.footer";
import Header from "@/bit/components/components.header";
import MetaData from "@/bit/core/core.meta-data";
import { connect } from "react-redux";
import { wrapper } from "@/store";
import dynamic from "next/dynamic";
import {
  getStaticPaths as getStaticPathsMethod,
  getStaticProps as getStaticPropsMethod,
} from "@/bit/pages/pages.ssr-pages-handler";

const Error500 = dynamic(() => import("@/pages/500"));
const DistriLayout = dynamic(() => import("@/layouts/distri"));
const DistriOrderedLayout = dynamic(() => import("@/layouts/distri-ordered"));
const FichasLayout = dynamic(() => import("@/layouts/fichas"));
const LayoutTv = dynamic(() => import("@/layouts/tv"));
const FichasOrderedLayout = dynamic(() => import("@/layouts/fichas-ordered"));
class Public extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orionInfo: props.orionInfo,
    };
  }

  render() {
    const {
      breadcrumbsProps,
      headerProps,
      seoInfo,
      orionAlias,
      custoInfo,
      layoutInfo,
      footerProps,
    } = this.props;
    const { orionInfo } = this.state;

    if (!orionInfo) {
      return <Error500 {...{ headerProps }} />;
    }

    return (
      <>
        <MetaData seoInfo={seoInfo} />
        <Header {...breadcrumbsProps} {...headerProps} />
        {layoutInfo === "ficha" && (
          <FichasLayout
            {...orionInfo}
            custoInfo={custoInfo}
            productName={orionAlias}
          />
        )}
        {layoutInfo === "tv" && (
          <LayoutTv
            {...orionInfo}
            productName={orionAlias}
            rehydrate={this.state.rehydrate}
          />
        )}
        {layoutInfo === "ficha-ordered" && (
          <FichasOrderedLayout
            {...orionInfo}
            custoInfo={custoInfo}
            productName={orionAlias}
          />
        )}
        {layoutInfo === "distri" && (
          <DistriLayout {...orionInfo} productName={orionAlias} />
        )}
        {layoutInfo === "distri-ordered" && (
          <DistriOrderedLayout {...orionInfo} productName={orionAlias} />
        )}
        <Footer footerProps={footerProps} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  context: state.main.context,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Public);

const getStaticPaths = () => getStaticPathsMethod("ssr-tv", 2);
export { getStaticPaths };

export const getStaticProps = wrapper.getStaticProps(async (data) => {
  return getStaticPropsMethod(data, "ssr-tv", 2);
});
